<template>
  <div>
    <!--loading state-->
    <template v-if="isLoading">
      <div class="text-center">
        <b-spinner variant="primary" label="Loading" />
      </div>
    </template>

    <template v-else>
      <!--cabecera solicitud -->
      <b-card no-body>
        <b-card-header class="align-items-baseline pb-1">
          <div>
            <b-card-title class="mb-25">
              <!-- back option -->
              <span @click="handleCloseForm">
                <feather-icon
                  icon="ArrowLeftIcon"
                  size="20"
                  class="align-bottom cursor-pointer"
                />
              </span>
              {{ formType.ftyNombre }}
            </b-card-title>
            <b-card-text class="mb-0">
              {{ formType.ftyCodigo }} {{ formType.ftyVersion }}
            </b-card-text>
          </div>

          <div>
            <!-- latest status -->
            <template v-if="isFormCancelled">
              <b-badge pill variant="light-danger" class="mr-2"
                >Anulada</b-badge
              >
            </template>

            <template v-else>
              <b-badge
                pill
                :variant="getLightFormStatusVariant"
                class="mr-2 text-uppercase"
                >
                {{ getFormStatusName }}
              </b-badge>
            </template>

            <b-dropdown
              variant="link"
              no-caret
              class="chart-dropdown"
              toggle-class="p-0"
              right
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="18"
                  class="text-body cursor-pointer"
                />
              </template>
              <b-dropdown-item @click="handleCancelForm">
                Anular formulario
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </b-card-header>

        <!-- info basica-->
        <b-row class="p-1">
          <!-- datos del colaborador -->
          <b-col md="8" sm="12">
            <b-form-group label="Colaborador">
              <v-select
                v-model="formLocal.empId"
                label="fullName"
                :options="employees"
                :reduce="(option) => option.empId"
                :clearable="false"
                :disabled="true"
                @input="handleUpdateColaborador"
              >
              </v-select>
            </b-form-group>

            <dl>
              <dt>Oficina</dt>
              <dd>{{ employeeData.offNombre }}</dd>
              <dt>Area | Cargo</dt>
              <dd>
                {{ employeeData.secNombre }}. {{ employeeData.posNombre }}
              </dd>
              <dt>Correo electrónico</dt>
              <dd>{{ employeeData.empEmail }}</dd>
            </dl>
          </b-col>

          <!-- datos del formulario -->
          <b-col md="4" sm="12">
            <b-form-group label="Secuencial">
              <!-- button on right -->
              <b-input-group class="input-group-merge disabled">
                <b-input-group-prepend is-text>
                  <feather-icon icon="HashIcon" />
                </b-input-group-prepend>
                <b-form-input
                  id="invoice-data-id"
                  v-model="formLocal.frmSecuencial"
                  disabled
                />
              </b-input-group>
            </b-form-group>
            <b-form-group label="Fecha de formulario">
              <flat-pickr
                v-model="formLocal.frmFecha"
                class="form-control"
                :config="configDate"
                :disabled="true"
              />
            </b-form-group>
            <b-form-group label="Fecha de vencimiento">
              <flat-pickr
                ref="datepicker"
                v-model="formLocal.frmFechaLimite"
                class="form-control"
                :config="configDateTime"
                :disabled="isReadOnly || isBusy"
                @on-change="handleChangeVencimiento"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <!-- save changes -->
        <b-row class="p-1" v-if="hasUnsavedChanges">
          <b-col>
            <b-button
              variant="secondary"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              @click="handleDiscardChanges"
              :disabled="isBusy"
            >
              Descartar
            </b-button>
            <b-button
              variant="primary"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              @click="handleSaveChanges"
              :disabled="isBusy"
            >
              Guardar cambios
            </b-button>
          </b-col>
        </b-row>

        <!-- errors section -->
        <template v-if="hasErrors">
          <b-row class="p-1">
            <b-col>
              <validation-errors :errors="errors" />
            </b-col>
          </b-row>
        </template>
      </b-card>

      <template v-if="!hasUnsavedChanges">
        <b-tabs lazy>
          <b-tab>
            <template #title>
              <feather-icon icon="BoxIcon" size="16" class="mr-0 mr-sm-50" />
              <span class="d-none d-sm-inline">Accesos</span>
            </template>
            <!-- empty state -->
            <template v-if="isEmptyApps">
              <b-card class="text-center">
                <feather-icon
                  icon="InfoIcon"
                  size="100"
                  class="mr-50 mb-3 mt-2"
                />
                <h2>No hay accesos registrados aún</h2>
                <p class="p-2">
                  Actualmente no hay tareas de modificación disponibles para
                  mostrar. Por favor, agregue una nueva tarea de modificación
                  utilizando el botón 'Modificar Acceso'.
                </p>
                <b-button
                  variant="primary"
                  class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                  :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                  @click="handleEditSystem"
                  :disabled="isBusy || isReadOnly"
                >
                  Modificar accesos
                </b-button>
              </b-card>
            </template>

            <!-- content section -->
            <template v-else>
              <b-button
                v-if="isDraftForm"
                variant="primary"
                class="mb-1 mr-0 mr-sm-1"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                @click="handleEditSystem"
                :disabled="isBusy || isReadOnly"
              >
                Modificar accesos
              </b-button>

              <!--aplicaciones asignadas-->
              <b-list-group>
                <b-list-group-item
                  class="d-flex justify-content-between"
                  v-for="(system, index) in formSystemsLocal"
                  :key="index"
                >
                  <div>
                    <span
                      ><strong>{{ system.sysNombre }}</strong></span
                    >

                    <b-badge
                      :variant="`light-${getToDoVariant(system.frsTarea)}`"
                      class="mx-1"
                    >
                      <span class="text-capitalize">{{
                        getToDoName(system.frsTarea)
                      }}</span>
                    </b-badge>

                    <div
                      class="d-flex flex-wrap justify-content-start align-items-center mt-1"
                    >
                      <b-badge
                        variant="light-secondary"
                        v-for="role in system.roles"
                        :key="role.rolId"
                        class="mr-1"
                      >
                        {{ role.rolNombre }}
                      </b-badge>
                    </div>
                  </div>

                  <b-button
                    variant="link"
                    class="p-0 text-danger"
                    @click="handleEliminarTaskLista(system.frsId)"
                    v-if="!isReadOnly"
                  >
                    <feather-icon icon="Trash2Icon" size="22" />
                  </b-button>
                </b-list-group-item>
              </b-list-group>
            </template>
          </b-tab>

          <!-- oficinas -->
          <b-tab>
            <template #title>
              <feather-icon icon="HomeIcon" size="16" class="mr-0 mr-sm-50" />
              <span class="d-none d-sm-inline">Oficinas</span>
            </template>

            <b-card class="text-center">
              <feather-icon
                icon="MapPinIcon"
                size="60"
                class="mr-50 mb-3 mt-2"
              />
              <h6><span class="text-muted">Oficina actual</span></h6>
              <h3>{{ employeeData.offNombre }}</h3>

              <template v-if="updateOfficeMode">
                <div>
                  <b-form class="mt-3">
                    <!--oficinas disponibles-->
                    <b-form-group label="Oficina destino">
                      <v-select
                        v-model="formOfficeModal.selectedOffice"
                        label="offNombre"
                        :options="officesOptions"
                        :reduce="(option) => option.offId"
                      />

                      <b-form-group label="Justificación">
                        <b-form-textarea
                          rows="3"
                          max-rows="8"
                          no-auto-shrink
                          v-model="formOfficeModal.textJustificacion"
                        />
                      </b-form-group>
                    </b-form-group>
                  </b-form>

                  <b-button
                    v-if="!isReadOnly"
                    variant="primary"
                    :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                    :disabled="isOfficeBusy || hasOfficeSomeNullEmptyItem"
                    @click="handleActualizarOficina()"
                    class="mx-md-1"
                  >
                    <span>Guardar cambio</span>
                  </b-button>

                  <b-button
                    v-if="!isReadOnly"
                    variant="secondary"
                    :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                    @click="handleCancelarActualizarOficina()"
                  >
                    <span>Descartar</span>
                  </b-button>
                </div>

                <validation-errors v-if="errors" :errors="errors" />
              </template>

              <template v-else>
                <!-- empty update office  -->
                <template v-if="isEmptyChangeOffice">
                  <b-button
                    variant="primary"
                    class="mt-2 mb-1 mb-sm-0 mr-0 mr-sm-1"
                    :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                    @click="handleUpdateOffice"
                    :disabled="isBusy || isReadOnly"
                  >
                    Cambiar oficina
                  </b-button>
                </template>
                <!-- update office request -->
                <template v-else>
                  <!-- badge -->
                  <b-list-group class="mt-2">
                    <b-list-group-item
                      class="d-flex justify-content-between align-items-center"
                      v-for="(item, index) in formSystemsTaskOffice"
                      :key="index"
                    >
                      <div>
                        <span
                          ><strong>{{
                            getOfficeName(item.frsOficinaActualId)
                          }}</strong></span
                        >

                        <b-badge
                          :variant="`light-${getToDoVariant(item.frsTarea)}`"
                          class="mx-1"
                        >
                          <span class="text-capitalize">{{
                            getToDoName(item.frsTarea)
                          }}</span>
                        </b-badge>

                        <p class="text-left">
                          {{ item.frsJustificacion }}
                        </p>
                      </div>

                      <b-button
                        variant="link"
                        class="p-0 text-danger"
                        @click="handleEliminarTaskLista(item.frsId)"
                        v-if="!isReadOnly"
                      >
                        <feather-icon icon="Trash2Icon" size="22" />
                      </b-button>
                    </b-list-group-item>
                  </b-list-group>
                </template>
              </template>
            </b-card>
          </b-tab>

          <!-- Historial de actividades -->
          <b-tab :disabled="isDraftForm">
            <template #title>
              <feather-icon
                icon="MessageSquareIcon"
                size="16"
                class="mr-0 mr-sm-50"
              />
              <span class="d-none d-sm-inline">Actividad</span>
            </template>

            <solicitud-creacion-edit-activity
              v-if="formLocal.frmId"
              :frm-id="formLocal.frmId"
            />
          </b-tab>
        </b-tabs>

        <!-- Boton para pasar de borrador a creada-->
        <b-button
          v-if="isReadyForm && !isReadOnly"
          variant="success"
          class="mt-1 mb-1 mr-0 mr-sm-1"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          @click="handleUpdateStatusForm"
          :disabled="isBusy"
        >
          Finalizar borrador y crear solicitud
        </b-button>
      </template>
    </template>

    <b-modal
      title="Modificar accesos"
      v-model="showModal"
      no-close-on-backdrop
      centered
      @hidden="handleCerrarModal"
    >
      <b-form-group label="Aplicación">
        <v-select
          v-model="formModal.selectedSystem"
          label="sysNombre"
          :options="systemsOptions"
          :reduce="(option) => option.sysId"
          @input="updateRoles"
        />
      </b-form-group>

      <b-form-group label="Roles">
        <v-select
          v-model="formModal.selectedRoles"
          label="rolNombre"
          :options="rolesOptions"
          :reduce="(option) => option.rolId"
          multiple
        />
      </b-form-group>

      <b-form-group label="Justificación">
        <b-form-textarea
          rows="3"
          max-rows="8"
          no-auto-shrink
          v-model="formModal.textJustificacion"
        />
      </b-form-group>

      <b-form-radio-group v-model="formModal.selectedTask" inline>
        <b-form-radio
          :value="resolveAddTaskCode"
          class="custom-control-success"
        >
          Agregar accesos
        </b-form-radio>

        <b-form-radio
          :value="resolveRemoveTaskCode"
          class="custom-control-danger"
        >
          Quitar accesos
        </b-form-radio>
      </b-form-radio-group>

      <template #modal-footer>
        <div class="w-100 text-right">
          <b-button
            variant="primary"
            :disabled="isBusy || hasSomeNullEmptyItem"
            @click="handleGuardarTarea"
          >
            <b-spinner small v-if="isBusy" />
            Aceptar
          </b-button>
          <b-button
            variant="secondary"
            class="ml-1"
            :disabled="isBusy"
            @click="handleCerrarModal"
          >
            Cerrar
          </b-button>
        </div>
      </template>

      <validation-errors v-if="errors" :errors="errors" />
    </b-modal>
  </div>
</template>

<script>
import {
  BBadge,
  BButton,
  BCard,
  BCardHeader,
  BCardText,
  BCardTitle,
  BCol,
  BDropdown,
  BDropdownItem,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BInputGroup,
  BInputGroupAppend,
  BInputGroupPrepend,
  BListGroup,
  BListGroupItem,
  BModal,
  BRow,
  BSpinner,
  BTab,
  BTabs,
  BFormRadio,
  BFormRadioGroup,
  BForm,
} from "bootstrap-vue";
import { mapActions, mapGetters } from "vuex";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import flatPickr from "vue-flatpickr-component";
import ValidationErrors from "@/modules/components/ValidationErrors.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { Spanish } from "flatpickr/dist/l10n/es.js";
import { FORM_TASK_ADD } from "@/modules/common/constants";
import { FORM_TASK_REMOVE } from "@/modules/common/constants";
import { FORM_TASK_OFFICE_UPDATE } from "@/modules/common/constants";

import {
  resolveFormStatusName,
  resolveLightFormStatusVariant,
  resolveToDoName,
  resolveToDoVariant
} from '@/modules/common/utils'

export default {
  name: "SolicitudModificacionEdit.vue",
  components: {
    BFormRadioGroup,
    BFormTextarea,
    BModal,
    BListGroup,
    BListGroupItem,
    BTab,
    BTabs,
    BForm,
    BButton,
    BInputGroupAppend,
    BInputGroup,
    BFormInput,
    BInputGroupPrepend,
    BFormGroup,
    BFormRadio,
    BRow,
    BCol,
    BDropdownItem,
    BDropdown,
    BBadge,
    BCardText,
    BCardTitle,
    BCardHeader,
    BCard,
    BSpinner,
    flatPickr,
    vSelect,
    ValidationErrors,
    SolicitudCreacionEditActivity: () =>
      import(
        "@/modules/solicitante/views/solicitudes/creacion/SolicitudCreacionEditActivity.vue"
      ),
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      errors: [],
      isLoading: false,

      isBusy: false,

      hasUnsavedChanges: false, // control de datos cambiados en la cabecera
      initializedData: false, // controla si los datos fueron inicializados por primera vez

      showModal: false,
      isDisabled: true,

      isOfficeBusy: false,

      formType: [],
      employees: [],
      employeeData: [],
      formLocal: {
        empId: null,
        frmFecha: null,
        frmFechaLimite: null,
      },

      // formSystemsLocal: [],
      formSystemsTask: [],
      //formSystemsTaskOffice: [],
      formOfficesLocal: [],

      formEditData: [],

      officesOptions: [],
      selectedOffice: null,
      systemsOptions: [],

      //data modal
      formModal: {
        selectedSystem: null,
        selectedRoles: null,
        textJustificacion: null,
        selectedTask: null,
      },
      //update ubicacion
      formOfficeModal: {
        selectedOffice: null,
        textJustificacion: null,
      },

      chosenSystem: {},
      chosenRoles: [],
      rolesOptions: [],
      extractedIds: Object.values({
        ...this.rolesOptions,
      }).map((obj) => obj.id), // Extraer los IDs

      configDate: {
        dateFormat: "Y-m-d",
        altInput: true,
        altFormat: "D, j-F-Y",
        locale: Spanish,
      },

      configDateTime: {
        dateFormat: "Y-m-d H:i",
        altInput: true,
        altFormat: "D, j-F-Y, H:i",
        locale: Spanish,
        enableTime: true,
      },

      updateOfficeMode: false,
    };
  },

  watch: {

    "formLocal.frmFechaLimite"() {
      if (this.initializedData) this.hasUnsavedChanges = true;
    },
  },

  computed: {
    ...mapGetters("sga-module", ["fetchOficinas"]),

    queryKey() {
      const { key } = this.$route.query;
      return key ?? null;
    },

    hasErrors(){

      return this.errors.length !== 0

    },

    selectedSystem() {
      return this.chosenSystem?.sysId ?? null;
    },

    // formulario tiene apps u offices
    isReadyForm() {
      return !this.isEmptyApps || !this.isEmptyChangeOffice;
    },

    isEmptyApps() {
      return this.formSystemsLocal.length === 0;
    },

    //
    isEmptyChangeOffice() {
      return this.formSystemsTaskOffice.length === 0;
    },

    //determinar si la solicitud/formulario ha sido activada
    isFormActived() {
      return Boolean(this.formLocal.frmEstado); // verdadero si es "1",1
    },
    //determinar si el formulario está anulado
    isFormCancelled() {
      return this.formLocal.frmAnulado !== null;
    },
    // si formulario está activado o anulado entonces no se puede modificar
    isReadOnly() {
      return this.isFormActived || this.isFormCancelled;
    },

    // verificar si formulario está en borrador para no activar tab ACTIVIDAD
    isDraftForm() {
      return !Boolean(this.formLocal.frmEstado); // verdadero si es "0",0
    },

    // verificar si algun elemento de un objeto es NULL o vacío
    hasSomeNullEmptyItem() {
      const nullable = (element) =>
        element === null ||
        element === "" ||
        (Array.isArray(element) && element.length === 0);
      return Object.values(this.formModal).some(nullable);
    },

    // establecer estilo de estado actual
    getLightFormStatusVariant(){
      const [ latestStatus ] = this.formLocal.statuses
      const status = {
        status: this.formLocal.frmEstado,
        latestStatus: latestStatus ? latestStatus.fstStatus : null
      }
      return resolveLightFormStatusVariant(status)
    },

    // establecer texto del estado actual
    getFormStatusName(){
      const [ latestStatus ] = this.formLocal.statuses
      const status = {
        status: this.formLocal.frmEstado,
        latestStatus: latestStatus ? latestStatus.fstStatus : null
      }
      return resolveFormStatusName( status )
    },
    //
    resolveAddTaskCode() {
      return FORM_TASK_ADD;
    },

    resolveRemoveTaskCode() {
      return FORM_TASK_REMOVE;
    },

    // verificar si algun elemento de un objeto es NULL o vacío
    hasOfficeSomeNullEmptyItem() {
      const nullable = (element) =>
        element === null ||
        element === "" ||
        (Array.isArray(element) && element.length === 0);
      return Object.values(this.formOfficeModal).some(nullable);
    },

    // accesos para modificar
    formSystemsLocal() {
      if (this.formEditData.sistemas)
        return this.formEditData.sistemas.filter((task) => task.sysId); // retornar solo registros con ID de sistema
      return [];
    },

    // task update office
    formSystemsTaskOffice() {
      if (this.formEditData.sistemas)
        return this.formEditData.sistemas.filter((task) => task.sysId === null); // retornar solo registros sin ID de sistema
      return [];
    },
  },

  methods: {
    ...mapActions("sga-module", ["findFormType", "getOficinas"]),
    ...mapActions("empleado-module", [
      "listEmpleados",
      "find",
      "updateEmpleado",
    ]),
    ...mapActions("aplicacion-module", ["listAplicaciones"]),
    ...mapActions("role-module", ["listRolesByApp"]),
    ...mapActions("solicitante-solicitudes-module", [
      "findOne",
      "loadTaskForm",
      "createFormSystem",
      "updateFormularioCreacion",
      "updateStatusFormularioCreacion",
      "cancelFormularioCreacion",
      "fetchFormSystems",
      "deleteFormSystem",
      "listSystemWithRoles",
      "createFormSystemOffice",
    ]),

    // obtener nombre tarea
    getToDoName(task) {
      return resolveToDoName(task);
    },
    // obtener estilo tarea
    getToDoVariant(task) {
      return resolveToDoVariant(task);
    },

    getOfficeName(office) {
      const officeFilter = this.formOfficesLocal.filter(
        (item) => item.offId == office
      );
      if (officeFilter) {
        const [officeSelected] = officeFilter;
        return officeSelected.offNombre;
      }
      return "No disponible";
    },

    handleShowModal() {
      this.showModal = true;
    },

    handleCerrarModal() {
      this.resetModal();
      this.showModal = false;
    },


    // procesar cambio de fecha vencimiento realizado
    handleChangeVencimiento(){
      if ( this.initializedData ){
        this.hasUnsavedChanges = true
      }
    },

    // actualizar datos del formulario
    async handleUpdateForm() {
      try {
        this.errors = [];
        this.isBusy = true;

        const dataForm = {
          frmId: this.formLocal.frmId,
          empId: this.formLocal.empId,
          frmUuid: this.formLocal.frmUuid,
          frmFecha: this.formLocal.frmFecha,
          frmFechaLimite: this.formLocal.frmFechaLimite,
        };

        await this.updateFormularioCreacion(dataForm);

        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Formulario",
            icon: "CheckIcon",
            text: "Datos actualizados correctamente",
            variant: "success",
          },
        });

        this.hasUnsavedChanges = false;
      } catch (error) {

        if (error.response.status === 422) {

          const  data  = error.response.data.errors ?? error.response.data

          console.log("UNXPECTED", error.response.data.errors);

          // errores de validacion
          this.errors = data;

        }
      } finally {
        this.isBusy = false;
      }
    },

    // actualizar por unica vez el estado del formulario de "en progreso" a "creado"
    async handleUpdateStatusForm() {
      const isConfirm = await this.$bvModal.msgBoxConfirm(
        "¿Estás listo para finalizar el borrador? Ten en cuenta que una vez que lo hagas, el borrador se guardará como versión final y no podrás hacer más cambios en él",
        {
          title: "Confirmar finalización de borrador",
          okVariant: "success",
          okTitle: "Sí, finalizar borrador",
          cancelTitle: "No",
          cancelVariant: "secondary",
          modalClass: "modal-success",
          hideHeaderClose: false,
          centered: true,
        }
      );

      if (!isConfirm) return;

      try {
        this.initializedData = false;

        this.errors = [];
        this.isBusy = true;
        const dataForm = {
          frmId: this.formLocal.frmId,
          frmUuid: this.formLocal.frmUuid,
          frmEstado: 1,
          frmFecha: this.formLocal.frmFecha,
          frmFechaLimite: this.formLocal.frmFechaLimite,
        };

        await this.updateStatusFormularioCreacion(dataForm);

        // await this.loadData();

        // actualizar estado formulario
        this.formLocal.frmEstado = 1

        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Formulario",
            icon: "CheckIcon",
            text: "El formulario ha sido creado!",
            variant: "success",
          },
        });

        this.initializedData = true;

      } catch (error) {
        if (error.response.status === 422) {
          const data = error.response.data.errors ?? error.response.data;

          console.log("UNXPECTED", error.response.data, data);

          // errores de validacion
          this.errors = data;
        }
      } finally {
        this.isBusy = false;
      }
    },

    // manejar peticio de agregar tareas de modificaicon al formulario
    async handleEditSystem() {
      try {
        this.isBusy = true;
        this.systemsOptions = [];
        const systemsOptions = await this.listAplicaciones();

        // excluir sistemas ya seleccionados en el formulario
        const alreadySystems = this.formSystemsLocal.map((item) => item.sysId);
        this.systemsOptions = systemsOptions.filter(
          (option) => !alreadySystems.includes(option.sysId)
        );

        this.showModal = true;
      } catch (error) {
      } finally {
        this.isBusy = false;
      }
    },

    async handleEliminarTaskLista(frsId) {
      try {
        await this.deleteFormSystem(frsId);
        await this.loadDataDetalles();
      } catch (error) {
        if (error.response.status != 204) {
          const data = error.response.data.errors;
          console.log("UNXPECTED", error.response.data);
          // errores de validacion
          this.errors = data;
        }
      }
    },

    async handleActualizarOficina() {

      this.errors = [];

      const dataUpdateEmployee = {
        frmId: this.formLocal.frmId,
        frsOficinaAnteriorId: this.employeeData.offId,
        frsOficinaActualId: this.formOfficeModal.selectedOffice,
        frsJustificacion: this.formOfficeModal.textJustificacion,
        frsTarea: FORM_TASK_OFFICE_UPDATE,
      };

      try {

        this.isOfficeBusy = true;
        await this.createFormSystemOffice(dataUpdateEmployee);

        //this.loadData();
        this.loadDataDetalles();
        this.resetOfficeForm();
        this.updateOfficeMode = false;

        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Formulario",
            icon: "CheckIcon",
            text: "Oficina guardada correctamente",
            variant: "success",
          },
        });
      } catch (error) {
        if (error.response.status === 422) {
          const data = error.response.data.errors;
          console.log("UNXPECTED", error.response.data);
          // errores de validacion
          this.errors = data;
        }
      } finally {
        this.isOfficeBusy = false;
      }
    },

    async handleGuardarTarea() {
      try {
        this.errors = [];
        this.isBusy = true;

        const formData = {
          frmId: this.formLocal.frmId,
          sysId: this.formModal.selectedSystem,
          frsJustificacion: this.formModal.textJustificacion,
          frsRoles: this.formModal.selectedRoles.join(","),
          frsTarea: this.formModal.selectedTask,
        };

        await this.createFormSystem(formData);

        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Formulario",
            icon: "CheckIcon",
            text: "Tarea guardada correctamente",
            variant: "success",
          },
        });

        await this.loadDataDetalles();

        this.showModal = false;

        this.isBusy = false;
      } catch (error) {
        if (error.response.status == 422) {
          const data = error.response.data.errors;

          console.log("UNXPECTED", error.response.data);

          // errores de validacion
          this.errors = data;
        }
      } finally {
        this.isBusy = false;
      }
    },

    // cerrar formulario modificacion
    handleCloseForm() {
      this.$router.push({ name: "solicitudes-menu" });
    },

    // anular formulario
    async handleCancelForm() {
      const isConfirm = await this.$bvModal.msgBoxConfirm(
        "Si anulas el formulario, cualquier proceso o tarea solicitada será suspendida de manera definitiva. ¿Deseas continuar?",
        {
          title: "Anular formulario",
          okVariant: "warning",
          okTitle: "Sí, anular formulario",
          cancelTitle: "No",
          cancelVariant: "secondary",
          modalClass: "modal-warning",
          hideHeaderClose: false,
          centered: true,
        }
      );

      if (!isConfirm) return;

      try {
        this.errors = [];
        this.isBusy = true;
        const params = {
          frmId: this.formLocal.frmId,
          frmUuid: this.formLocal.frmUuid,
        };

        await this.cancelFormularioCreacion(params);

        await this.loadData();

        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Formulario anulado",
            icon: "CheckIcon",
            text: " El formulario ha sido anulado correctamente y todas las tareas asociadas han sido canceladas.",
            variant: "success",
          },
        });
      } catch (error) {
      } finally {
        this.isBusy = false;
      }
    },

    // reset data modal
    resetModal() {
      this.formModal.selectedSystem = null;
      this.formModal.selectedRoles = null;
      this.formModal.textJustificacion = null;
      this.formModal.selectedTask = null;
    },
    // verificar si algun elemento de un objeto es NULL o vacío

    // cargar detalles
    async loadDataDetalles() {
      try {
        this.isBusy = true;

        // busca los accesos existentes.
        const formTasks = await this.loadTaskForm({ uuid: this.queryKey });
        this.formEditData = formTasks;

        // cargar oficinas
        this.formOfficesLocal = this.fetchOficinas();

        const employeeOfficeId = this.employeeData.offId;

        this.officesOptions = this.formOfficesLocal.filter(function (office) {
          return office.offId !== employeeOfficeId;
        });
      } catch (error) {
        console.log("CATCH LOAD DATA DETAIL", error);
      } finally {
        this.isBusy = false;
      }
    },



    async loadData() {
      try {

        this.isBusy = true;
        this.formLocal = await this.findOne({ uuid: this.queryKey });
        await this.updateColaborador()

      } catch (error) {
        console.log("CATCH LOAD DATA", error);
      } finally {
        this.isBusy = false;
      }
    },

    // procesar peticion de descartar cambios
    async handleDiscardChanges() {

      this.errors = []

      await this.loadData()

      this.hasUnsavedChanges = false
      this.initializedData = true
    },

    // procesar peticion para guardar cambios
    handleSaveChanges() {
      this.handleUpdateForm();
    },

    async initializeReferences() {
      try {
        this.isLoading = true;
        this.formType = [];
        this.employees = [];
        //tipo de formulario
        this.formType = await this.findFormType({ id: this.formLocal.ftyId });

        //lista de empleados
        const employees = await this.listEmpleados();
        this.employees = employees.map((employee) => ({
          empId: employee.empId,
          empNombres: employee.empNombres,
          empApellidos: employee.empApellidos,
          fullName: `${employee.empNombres} ${employee.empApellidos}`,
        }));

        this.isLoading = false;
      } catch (error) {
        console.log("catch initializeReferences", error);
      }
    },

    // buscar roles de la aplicacion
    async updateRoles() {
      this.formModal.selectedRoles = [];
      this.rolesOptions = [];

      try {
        this.isBusy = true;

        this.rolesOptions = await this.listRolesByApp({
          sysId: this.formModal.selectedSystem,
        });
      } catch (error) {
        console.log("CATCH ROLES", error);
      } finally {
        this.isBusy = false;
      }
    },


    // procesar cambio realizado en el colaborador
    async handleUpdateColaborador(){
      await this.updateColaborador()
      if ( this.initializedData ){
        this.hasUnsavedChanges = true
      }
    },

    // actualizar referencias del colaborador seleccionado
    async updateColaborador() {
      this.employeeData = await this.find({ id: this.formLocal.empId });
    },

    // manejar modo update office
    handleUpdateOffice() {
      this.updateOfficeMode = true;
    },

    handleCancelarActualizarOficina() {
      this.resetOfficeForm();
      this.updateOfficeMode = false;
    },

    resetOfficeForm() {
      this.formOfficeModal.selectedOffice = null;
      this.formOfficeModal.textJustificacion = null;
    },
  },

  async created() {

    await this.getOficinas()
    await this.loadData();
    await this.loadDataDetalles();
    await this.initializeReferences();

    this.initializedData = true;
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
